// FONTS

@font-face {
  font-family: "PoppinsMedium";
  src: url(../assets/fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: "Open Sans";
  src:
    url("../assets/fonts/OpenSans-Medium.woff2") format("woff2"),
    url("../assets/fonts/OpenSans-Medium.woff") format("woff"),
    url("../assets/fonts/OpenSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans Regular";
  src:
    url("../assets/fonts/OpenSans-Regular.woff2") format("woff2"),
    url("../assets/fonts/OpenSans-Regular.woff") format("woff"),
    url("../assets/fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans Bold";
  src:
    url("../assets/fonts/OpenSans-Bold.woff2") format("woff2"),
    url("../assets/fonts/OpenSans-Bold.woff") format("woff"),
    url("../assets/fonts/OpenSans-Bold.ttf") format("truetype");
}

$mainFont: "PoppinsMedium", sans-serif;

// THEME COLORS
$bgcolor: black;
$bgiconscolor: rgba(0, 0, 0, 0.75);
$bgiconscolorH: rgba(0, 0, 0, 0.85);

// FONT COLORS
$fontcolor1T: #e2b25954;
$fontcolor1: #e2b259;
$fontcolor1H: #b1852d;
$fontcolorchecked: #50b33a;
$fontcolorcheckedH: #2e6f1d;

// OTHERS
$black: black;
$black2: rgb(57, 57, 57);
$white: linen;
$white2: rgb(186, 186, 186);
$red: rgb(175, 33, 33);
$red2: rgb(255, 0, 0);
$borderWhite: 2px solid $white;
$borderBlack: 2px solid $black;

// PAGES JAUNES

$yellow:    #FFDE07;
$blue:      #64D6DF;
$grey2:     #D1D4D7;

$logowidth:  250px;
$motorheight: 42px;

// MEDIA QUERIES

$mobile: 768px;
$tablet: 1024px;
$desktop: 1340px;

// MIXINS

@mixin respond-to($breakpoint) {
  @if $breakpoint == small {
    @media (max-width: $mobile) { @content; }
  } @else if $breakpoint == medium {
    @media (max-width: $tablet) { @content; }
  } @else if $breakpoint == large {
    @media (max-width: $desktop) { @content; }
  }
}

@mixin set-background-neutral {
  background: $bgcolor;
  min-height: 100vh;
}

@mixin setup-admin{
  background: url("../assets/images/background-earth.jpg") fixed center/cover;
  min-height: 100vh;
  .admin-content-container {
    background-color: $bgiconscolorH;
    color: $white;
    border: 2px solid $white;
    border-radius: 20px;
    width: 90%;
    margin: 10px auto;
  }
}

@mixin label-button{
  display: flex;
  padding: 10px;
  margin: 10px auto;
  background-color: $fontcolor1;
  color: $black;
  border: 2px solid $white;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: $fontcolor1H;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: $mainFont;
  scrollbar-color: $fontcolor1 $black;
  scrollbar-width: thin;
}

body {
  background: $black;
}