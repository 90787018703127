.connexion, .forget-password, .reset-password {
  width: 100%;

  form.form-connexion, form.form-forget-password, form.form-reset-password {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
    input {
      font-size: large;
      margin: 20px 0;
      padding: 5px;
      width: 100%;
      height: 40px;
      background-color: $white;
      border: 2px solid $fontcolor1H;
      border-radius: 10px;

      &::placeholder {
        color: black;
      }

      &:focus {
        outline: none;
        border: 2px solid $black;
      }
    }
  }

  .form-links {
    display: flex;
    justify-content: space-between;
    margin: 0 20%;

    a {
      color: $fontcolor1H;
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        color: $fontcolor1;
      }
    }

    @include respond-to(small) {
      margin: 0 10px;
    }
  }
}