.admin-filter-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: 30px auto;
  padding: 20px;
  border: 2px solid $white;
  border-radius: 20px;

  input {
    width: 70%;
    height: 30px;
    padding: 5px;
    background: $fontcolor1;
    border: 1px solid $white;
    border-radius: 5px;
    transition: 0.2s ease;

    &::placeholder {
      color: $black2;
    }

    &:hover {
      background: $fontcolor1H;
    }

    &:focus {
      background: $fontcolor1H;
    }
  }

  .name-select {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;

    input{
      margin-left: 30px;
    }
  }

  .other-filters {
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    > div {
      flex: 1;
      min-width: 200px;
    }

    .country-select, .city-select, .category-select, .postalcode-select, .active-select, .date-filter, .price-filter{
      text-align: center;

      .date-input, .price-input {
        display: flex;
        flex-direction: column;
        align-items: center;
        }
    }

    .price-filter{
      .price-range{
        display: flex;
        flex-direction: column;
        gap:10px;
      }
    }



    select {
      margin: 10px 0;
      padding: 5px;
      font-size: 1rem;
      background-color: $fontcolor1;
      border: 2px solid $white;
      border-radius: 5px;
      transition: 0.2s ease;
      color: black;

      &:hover {
        border: 2px solid $white2;
      }
    }

    .date-publication {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 20px;
      white-space: nowrap;

      .date-input{

        label {
          margin-bottom: 5px;
          font-size: 1rem;
          color: $white;
        }

        input {
          height: 100%;
          width: 100%;
          padding: 5px;
          background: $fontcolor1;
          border: 1px solid $white;
          border-radius: 5px;
          transition: 0.2s ease;

          &::placeholder {
            color: $black2;
          }

          &:hover {
            background: $fontcolor1H;
          }

          &:focus {
            background: $fontcolor1H;
          }
        }
      }
    }
  }
}