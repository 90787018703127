section.new-account-user, section.admin-new-user {
  margin-bottom: 40px;


  form.form-admin-new-user, form.form-new-account-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    h3 {
      text-align: center;
      margin-bottom: 20px;
      color: $fontcolor1;
    }

    .info-new-user.center-content {
      justify-content: center;

      .info-personnels-new-user {
        width: 60%;
      }
    }

    @include respond-to(small) {
      .info-new-user.center-content {
        justify-content: center;

        .info-personnels-new-user {
          width: 90%;
        }
      }
    }

    .info-new-user {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .info-personnels-new-user,
      .info-professionels-new-user {
        width: 48%;
        display: flex;
        flex-direction: column;

        input {
          width: 100%;
          min-height: 40px;
          margin: 10px 0;
          padding: 10px;
          background: $fontcolor1;
          border: 1px solid $white;
          border-radius: 5px;
          transition: 0.2s ease;

          &::placeholder {
            color: $black;
          }

          &:hover {
            background: $fontcolor1;
          }

          &:focus {
            background: $fontcolor1;
            outline: none;
          }
        }

        .uploaded-logo {
          margin-top: 20px;
          text-align: center;
        }

        .uploaded-logo img {
          max-width: 100%;
          height: auto;
          border: 2px solid #ccc;
          border-radius: 4px;
        }

        .button-label {
          @include label-button;
        }

        .hide-on-large {
          display: none;

          @include respond-to(small) {
            display: block;
            margin: 30px auto;
          }
        }
      }
    }

    .general-condition {
      display: flex;
      flex-direction: column;
      margin: 0 10px;

      .general-condition-check-box-label {
        display: flex;

        p {
          margin-top: 30px;
        }
      }
    }
  }

  @include respond-to(small) {
    form {
      padding: 10px;

      .info-new-user {
        flex-direction: column;
        align-items: center;


        .info-personnels-new-user,
        .info-professionels-new-user {
          width: 90%;
        }
      }
    }
  }
}